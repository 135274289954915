/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useRef, useState } from 'react'
import { Route, Switch, RouteComponentProps } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import './styles/Layout.scss'

import audioFile from 'assets/sounds/plucky.mp3'

import { ConversationTypes, Message } from 'panel/conversation/redux/types'
import { ActionResponse } from 'util/types/index'
import { getSellerLogged } from 'panel/seller/redux/actions'
import { authenticateRefresh } from 'panel/user/redux/actions'
import { AuthRefreshData, AuthResponse } from 'panel/user/redux/types'
import { getPayload, getToken, signOut } from 'util/services/AuthService'
import { Schedule as ScheduleType, ScheduleTypes, ScheduleSendStatus, ScheduleSend } from 'panel/schedule/redux/types'

// Root
import Alert from 'util/modules/Alert'

// Components
import Navbar from './components/Navbar'
import Sidebar from './components/Sidebar'

import UserManager from 'panel/user/manager/UserManager'

// APP
import ChatBot from 'panel/chatbot/ChatBot'
import Conversation from 'panel/conversation/Conversation'
import MessageDatabase from 'panel/message-database/MessageDatabase'

// Configuration
import Tag from 'panel/tag/TagPage'
import Group from 'panel/group/Group'
import Sector from 'panel/sector/Sector'
import Person from 'panel/person/Person'
import Channel from 'panel/channel/Channel'
import Attendant from 'panel/attendant/Attendant'

// Admin
import Seller from 'panel/seller/Seller'
import { initSocket, getSocket } from 'util/services/SocketService'

import Plan from 'panel/plan/Plan'
import Store from 'panel/store/Store'
import Client from 'panel/client/Client'
import Stores from '../dashboard/Stores'
import Loader from './components/Loader'
import Invoice from 'panel/invoice/Invoice'
import Schedule from 'panel/schedule/Schedule'
import Statistic from 'panel/report/Statistics'

import { sleep } from 'util/index'
import { getConfigs } from 'panel/config/redux/actions'
import { useDebounce } from 'util/hooks'
import { TitleTicker } from 'panel/conversation/components/TitleTicker'
import { getPersonList } from 'panel/person/redux/action/person'
import { defaultPicture } from 'index'
import { ApplicationState } from 'AppReducer'
import { getConversation, getConversationList } from 'panel/conversation/redux/actions'
import { Contact, ContactTypes } from 'panel/person/redux/types/contact'
import { getChannel, getChannelList } from 'panel/channel/redux/actions'
import { getClientLogged, getClientList } from 'panel/client/redux/actions'
import { getSellerClientList, selectStore } from 'panel/store/redux/actions'
import { newConversationMessage, updateConversationMessage } from 'panel/conversation/redux/actions'
import { useQueryClient } from '@tanstack/react-query'
import { PersonTypes } from 'panel/person/redux/types/person'
import ChatBotNew from 'panel/chat-bot/chat-bot'
import Prompt from 'panel/prompt/Prompt'
import Survey from 'panel/report/Survey'

const Layout: React.FC<RouteComponentProps> = ({ match }) => {
  const dispatch = useDispatch()

  const isConversationPage = window.location.pathname.search('/conversation') > 0

  const audioRef = useRef<HTMLAudioElement>(null)
  const [isSidebarCollapsed, setSideBarCollapsed] = useState(false)

  const [message, setMessage] = useState('')

  const [canRender, setCanRender] = useState(false)

  const { logged } = useSelector((state: ApplicationState) => state.userReducer)
  const selected = useSelector((state: ApplicationState) => state.storeReducer.selected)

  const onSidebarCollapsedChange = () => {
    setSideBarCollapsed(!isSidebarCollapsed)
  }

  function handleCleanReducers (cleanUser: boolean): void {
    dispatch({ type: 'BOT/RESET' })
    dispatch({ type: 'TAG/RESET' })
    dispatch({ type: 'PLAN/RESET' })
    dispatch({ type: 'GROUP/RESET' })
    dispatch({ type: 'STORE/RESET' })
    dispatch({ type: 'PERSON/RESET' })
    dispatch({ type: 'CLIENT/RESET' })
    dispatch({ type: 'SECTOR/RESET' })
    dispatch({ type: 'SELLER/RESET' })
    dispatch({ type: 'MESSAGE/RESET' })
    dispatch({ type: 'CHANNEL/RESET' })
    dispatch({ type: 'CONTACT/RESET' })
    dispatch({ type: 'SCHEDULE/RESET' })
    dispatch({ type: 'ATTENDANT/RESET' })
    dispatch({ type: 'TIMETABLE/RESET' })
    dispatch({ type: 'BOT/OPTION/RESET' })
    dispatch({ type: 'CONVERSATION/RESET' })
    dispatch({ type: 'INVOICE_RESET/RESET' })

    if (cleanUser) {
      dispatch({ type: 'USER/RESET' })
    }
  }

  function handleRefreshToken (): Promise<ActionResponse<AuthResponse>> {
    const payload = getPayload()
    const { refreshToken } = getToken()
    if (!payload.sub || !refreshToken) throw new Error('Token não disponível')

    const body: AuthRefreshData = { id: payload.sub, token: refreshToken }
    return authenticateRefresh(body)(dispatch)
  }

  async function handleAuthenticate () {
    setMessage('Autenticando, aguarde...')
    const onError = async () => {
      setMessage('Não autenticado, necessário login...')
      await sleep(2000)
      signOut()
    }
    try {
      await handleRefreshToken()
        .then(async (res) => {
          if (res?.status === 'success' && res.data?.user) {
            setMessage('Autenticação concluída com sucesso!')
            await sleep(1000)
            setMessage('')
            try {
              initSocket({ token: res.data.token })
            } catch (e) {
              console.log('Erro ao conectar ao socket', e)
            }
          } else if (res.code === 401 || res.code === 400) {
            await onError()
          } else if (res.code === 500) {
            setMessage('Servidor indisponível, tentando novamente em 5 segundos...')
            await sleep(5000)
            handleAuthenticate()
          }
        })
        .catch(async () => {
          await onError()
        })
    } catch {
      await onError()
    }
  }

  useEffect(() => {
    handleAuthenticate()
  }, [dispatch])

  useEffect(() => {
    if (logged) {
      handleCleanReducers(false)

      getClientLogged()(dispatch)
      getSellerClientList()(dispatch).then(res => {
        const stores = res.data || []
        if (stores.length) {
          const selected = Number(localStorage.getItem('@App:Store'))

          if (selected) {
            const find = stores.find(store => store.id === selected)
            if (!find) {
              localStorage.removeItem('@App:Store')
              selectStore(stores[0].id)(dispatch)
            } else {
              selectStore(selected)(dispatch)
            }
          } else {
            selectStore(stores[0].id)(dispatch)
          }
        } else {
          localStorage.removeItem('@App:Store')
          selectStore(0)(dispatch)
        }

        setCanRender(true)
      })

      const isAdmin = !!logged.roles.find(role => role === 'ROLE_ADMIN')
      const isSeller = !!logged.roles.find(role => role === 'ROLE_SELLER')

      if (isAdmin) getClientList()(dispatch)
      if (isSeller) getSellerLogged()(dispatch)
      if (logged.permissions.includes('CONFIG:LIST')) getConfigs()(dispatch)
    } else {
      handleCleanReducers(true)
    }
  }, [logged, dispatch])

  const debounceAudio = useDebounce()
  const queryClient = useQueryClient()
  const debounceContactUpdate = useDebounce()
  const debounceConversationList = useDebounce()
  const debounceUpdateChannelList = useDebounce()

  // Handle Socket
  const socket = getSocket()
  useEffect(() => {
    type Action = 'UPDATE' | 'UPDATE_LIST' | 'NEW_MESSAGE' | 'UPDATE_MESSAGE' | 'NEW_CONVERSATION'
    type SocketConversation = {
      id?: number,
      action?: Action[],
      message?: Message,
      initiation?: string,
      notification?: { title: string, body: string, picture?: string }
    }

    if (socket && selected && logged) {
      if (socket) {
        socket.on('@Contact:Validated', (contact: Contact) => {
          dispatch({ type: ContactTypes.CONTACT_UPDATE, payload: contact })
          dispatch({ type: PersonTypes.PERSON_UPDATE_CONTACT, payload: contact })

          debounceContactUpdate(() => {
            const query = new URLSearchParams(window.location.search)
            getPersonList(selected, {
              page: query.get('page') || 1,
              limit: 10,
              fields: 'id,name,createdAt,updatedAt'
            })(dispatch)

            queryClient.invalidateQueries(['contact', { number: contact.value }])
          }, 2000)
        })

        socket.on(`@Store:${selected}:Conversation`, ({ action, id, message, notification }: SocketConversation) => {
          if (action?.includes('UPDATE') && id) { // Update conversation list
            debounceConversationList(() => { getConversation(selected, id)(dispatch) }, 5000)
          }

          if (action?.includes('UPDATE_LIST')) { // Update conversation list
            debounceConversationList(() => { getConversationList(selected)(dispatch) }, 5000)
          }

          if (action?.includes('NEW_MESSAGE') && id && message) {
            newConversationMessage(id, message)(dispatch)

            if (notification) {
              const openId = Number(window.location.pathname.split('/').pop())
              const isDifferentConversation = openId !== id

              if (!document.hasFocus() || isDifferentConversation) {
                let body = notification.body
                if (message?.attachment?.type === 'LOCATION') body = `📍 ${body}`

                new Notification(notification.title, {
                  body,
                  icon: notification.picture || defaultPicture,
                  // @ts-ignore
                  vibrate: [200, 100, 200]
                } as any)
              }
            }
          }

          if (action?.includes('UPDATE_MESSAGE') && id && message) { // Update Message
            updateConversationMessage(id, message)(dispatch)
          }
        })

        type SocketNotificationPayload = { id: number, store: number, unreadMessages: number }
        socket.on(`@User:${logged.id}:Notification`, ({ id, store, unreadMessages }: SocketNotificationPayload) => {
          const openId = Number(window.location.pathname.split('/').pop())
          const canNotify = id !== openId

          if (store === selected && canNotify && unreadMessages > 0) {
            debounceAudio(() => {
              if (audioRef.current) audioRef.current.play()
            }, 1000)
          }

          dispatch({ type: ConversationTypes.CONVERSATION_UPDATE_UNREAD, conversationId: id, unreadMessages })
        })

        socket.on(`@User:${logged.id}:UnreadMessage`, ({ id, unreadMessages }: SocketNotificationPayload) => {
          dispatch({ type: ConversationTypes.CONVERSATION_UPDATE_UNREAD, conversationId: id, unreadMessages })
        })

        socket.on(`@Store:${selected}:UpdateChannel`, (channelId: number) => {
          debounceUpdateChannelList(() => {
            getChannel(selected, channelId, false)(dispatch)
            getChannelList(selected)(dispatch)
          }, 1000)
        })

        socket.on(`@Store:${selected}:User:${logged.id}:Redirect`, () => {
          debounceAudio(() => {
            if (audioRef.current) audioRef.current.play()

            new Notification('Redirecionamento', {
              body: 'Você recebeu uma nova solicitação',
              // @ts-ignore
              vibrate: [200, 100, 200]
            })
          }, 1000)
        })

        socket.on(`@Store:${selected}:Schedule`, (payload: { schedule: ScheduleType, sends: ScheduleSendStatus[] }) => {
          dispatch({ type: ScheduleTypes.SCHEDULE_GET, payload: payload.schedule })
          dispatch({ type: ScheduleTypes.SCHEDULE_GET_SENDS, payload: payload.sends })
        })

        socket.on(`@Store:${selected}:Schedule:Send`, (payload: ScheduleSend) => {
          dispatch({ type: ScheduleTypes.SCHEDULE_UPDATE_SEND, payload })
        })
      }
    }

    return () => {
      if (logged && socket && selected) {
        console.log('Logout')
        socket.off(`@Store:${selected}:Schedule`)
        socket.off(`@Store:${selected}:Conversation`)
        socket.off(`@User:${logged.id}:Notification`)
        socket.off(`@Store:${selected}:UpdateChannel`)
        socket.off(`@Store:${selected}:User:${logged.id}:Redirect`)
      }
    }
  }, [socket, selected, logged])

  function isIOS () {
    const browserInfo = navigator.userAgent.toLowerCase()

    if (browserInfo.match('iphone') || browserInfo.match('ipad')) {
      return true
    }

    const types = ['iPad Simulator', 'iPad', 'iPhone Simulator', 'iPhone', 'iPod Simulator', 'iPod']
    const platform = (navigator as any)?.userAgentData?.platform || navigator?.platform || 'unknown'

    if (types.includes(platform)) {
      return true
    }
    return false
  }

  useEffect(() => {
    if (isIOS()) return
    if (!Notification) return
    if (Notification.permission === 'granted') return

    Notification.requestPermission()
  }, [])

  const theme = localStorage.getItem('data-theme')

  if (!canRender) return <Loader message={message} />

  const collapsedClass = isConversationPage || isSidebarCollapsed ? 'collapsed' : ''

  return (
    <>
      <Alert />
      <TitleTicker />

      <audio ref={audioRef}><source src={audioFile} type="audio/mp3" /></audio>

      <div
        id="themeMode"
        className={`row layout-panel ${collapsedClass} ${isConversationPage ? 'conversation-page' : ''}`}
        data-theme={theme || 'light'}
      >
        <Navbar onSidebarCollapsedChange={onSidebarCollapsedChange} />
        <Sidebar onSidebarCollapsedChange={onSidebarCollapsedChange} sideBarCollapsed={isSidebarCollapsed} />

        <div
          className="content"
          style={{
            padding: location.pathname.includes('/panel/prompt') ? '0px' : '',
            scrollbarWidth: location.pathname.includes('/panel/prompt') ? 'none' : 'auto'
          }}
        >
          <Switch>
            <Route exact path={`${match.url}`} component={Stores} />
            <Route path={`${match.url}/tag/:id?/:action?`} component={Tag} />
            <Route path={`${match.url}/plan/:id?/:action?`} component={Plan} />
            <Route path={`${match.url}/store/:id?/:action?`} component={Store} />
            <Route path={`${match.url}/group/:id?/:action?`} component={Group} />
            <Route path={`${match.url}/sector/:id?/:action?`} component={Sector} />
            <Route path={`${match.url}/client/:id?/:action?`} component={Client} />
            <Route path={`${match.url}/seller/:id?/:action?`} component={Seller} />
            <Route path={`${match.url}/person/:id?/:action?`} component={Person} />
            <Route path={`${match.url}/channel/:id?/:action?`} component={Channel} />
            <Route path={`${match.url}/chat-bot/:id?/:action?`} component={ChatBotNew} />
            <Route path={`${match.url}/chatbot/:id?/:action?`} component={ChatBot} />
            <Route path={`${match.url}/invoice/:id?/:action?`} component={Invoice} />
            <Route path={`${match.url}/schedule/:id?/:action?`} component={Schedule} />
            <Route path={`${match.url}/attendant/:id?/:action?`} component={Attendant} />
            <Route path={`${match.url}/message-database/:id?/:action?`} component={MessageDatabase} />
            <Route path={`${match.url}/prompt`} component={Prompt} />

            <Route path={`${match.url}/conversation/:id?`} component={Conversation} />

            <Route exact path={`${match.url}/chatbot/:botId/option`} component={ChatBot} />
            <Route exact path={`${match.url}/user/:id?`} component={UserManager} />
            <Route exact path={`${match.url}/report/statistic`} component={Statistic} />
            <Route exact path={`${match.url}/report/survey`} component={Survey} />
          </Switch>
        </div>
      </div>
    </>
  )
}

export default Layout
