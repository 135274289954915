import React from 'react'

import { getChatBotSurvey } from './services/chat-bot-survey.service'
import './styles/SurveyReport.scss'
import ReactApexChart from 'react-apexcharts'
import { maskDate } from 'util/masks'
import { DateObject } from 'util/DateObject'
import { getRandomInt } from 'util/index'

const start = DateObject.now().subtractDay(7)
const end = DateObject.now()

const Survey: React.FC = () => {
  const { data } = getChatBotSurvey()
  const [filter, setFilter] = React.useState({ start: start.toBrazilianDateFormat(), end: end.toBrazilianDateFormat() })

  const renderStars = (rating: number) => {
    const stars = []
    const fullStars = Math.floor(rating)
    const hasHalfStar = rating % 1 !== 0
    for (let i = 0; i < 5; i++) {
      if (i < fullStars) {
        stars.push(<i key={i} className="fa fa-star" />)
      } else if (i === fullStars && hasHalfStar) {
        stars.push(<i key={i} className="fa fa-star-half-stroke" />)
      } else {
        stars.push(<i key={i} className="far fa-star" />)
      }
    }
    return stars
  }

  return (
    <div className="row justify-end survey-report-container" style={{ gap: 16 }}>
      <div className="row align-end justify-end" style={{ width: 'auto' }}>
        <div className="form-control" style={{ width: 200 }}>
          <label>Inicio</label>
          <input
            type="text"
            value={filter.start}
            onChange={(e): void => setFilter({ ...filter, start: maskDate(e.target.value) })}
          />
        </div>
        <div className="form-control" style={{ width: 200 }}>
          <label>Fim</label>
          <input
            type="text"
            value={filter.end}
            onChange={(e): void => setFilter({ ...filter, end: maskDate(e.target.value) })}
          />
        </div>
        <button className="gradient" style={{ height: 48 }}>
          <i className="fa fa-filter" />Filtrar
        </button>
      </div>

      <div className="row padding-top-8 chart-content panel">
        <p className="grow" style={{ fontSize: 18 }}>Atendimentos</p>
        <ReactApexChart
          type='bar'
          height={300}
          style={{ width: '100%' }}
          series={[{
            name: 'Atendimentos',
            data: [...new Array(30)].map((n, i) => getRandomInt(0, 100))
          }]}
          options={{
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '55%',
                borderRadius: 5,
                borderRadiusApplication: 'end'
              }
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              show: true,
              width: 2,
              colors: ['transparent']
            },
            xaxis: {
              categories: [...new Array(30)].map((n, i) => `${i + 1}/02`)
            },
            yaxis: {
              title: {
                text: 'Quantidade'
              }
            },
            fill: {
              opacity: 1
            }
          }}
        />
      </div>
      <div className="row padding-top-8 chart-content panel">
        <p className="grow" style={{ fontSize: 18 }}>Quantidade de avaliações</p>
        <ReactApexChart
          type='area'
          height={300}
          style={{ width: '100%' }}
          series={[{
            name: 'Atendimentos',
            data: [...new Array(30)].map((n, i) => getRandomInt(0, 100))
          }]}
          options={{
            fill: {
              type: 'gradient',
              gradient: {
                gradientToColors: ['#4158D0', '#C850C0'],
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 90, 100]
              }
            },
            xaxis: {
              categories: [...new Array(30)].map((n, i) => `${i + 1}/02`)
            },
            yaxis: {
              title: {
                text: 'Quantidade'
              }
            }
          }}
        />
      </div>
      <div className="row padding-top-8 chart-content panel">
        <p className="grow" style={{ fontSize: 18 }}>Média de avaliações</p>
        <ReactApexChart
          type='area'
          height={300}
          style={{ width: '100%' }}
          series={[{
            name: 'Atendimentos',
            data: [...new Array(30)].map((n, i) => getRandomInt(0, 100))
          }]}
          options={{
            fill: {
              type: 'gradient',
              gradient: {
                shadeIntensity: 1,
                gradientToColors: ['#0093E9', '#80D0C7'],
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 90, 100]
              }
            },
            xaxis: {
              categories: [...new Array(30)].map((n, i) => `${i + 1}/02`)
            },
            yaxis: {
              title: {
                text: 'Quantidade'
              }
            }
          }}
        />
      </div>

      <div className="list">
        <div className="list-header">
          <div>Atendente</div>
          <div>Nota</div>
          <div>Tempo de atendimento</div>
        </div>

        {/* {
          stores.length === 0 &&
          <div className="list-row empty">
            <SellerUser width={120} height={120} style={{ padding: 24 }} />
            <div className="grid-span" style={{ height: 0 }}>Nenhum loja cadastrada</div>
          </div>
        } */}

        <div className="list-row white">
          <div data-content="Atendente">Pedro</div>
          <div data-content="Nota">
            {renderStars(4)}
          </div>
          <div data-content="Tempo de atendimento">10m</div>
        </div>
      </div>
    </div>
  )
}

export default Survey
