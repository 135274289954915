import { useQuery } from '@tanstack/react-query'

import { Api } from 'util/services/api'

export const getChatBotSurvey = () => {
  return useQuery<GetSurveyReportOutput>(
    ['chat-bot-survey'],
    async () => {
      const { data } = await Api.get<GetSurveyReportOutput>('/bot/survey/report')
      return data || []
    },
    {
      enabled: true,
      refetchOnWindowFocus: false,
      initialData: []
    }
  )
}

export type GetSurveyReportOutput = {
  title: string,
  answers: { value: string, count: number }[]
}[]
